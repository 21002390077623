import React, {Component} from 'react';
import ButtonComponent from '../../../shared/button/components/ButtonComponent';
import '../style/SelectCollection.scss';
import 'react-id-swiper/lib/styles/scss/swiper.scss';
import axios from 'axios';
import LoadingComponent from '../../../shared/loading/components/LoadingComponent';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import { Helmet } from 'react-helmet-async';
class SelectCollectionBox extends Component {
    // t('ORDER NOW', 'ORDER NOW')
    state = {
        collections: [],
        loading: true,
        noCollections: false,
        pricelist: 0,
        collectionPricelists: [],
    };

    getCollections() {
        axios
            .get(`${process.env.REACT_APP_NEW_API_URL}/v1/sellers/${this.props.seller}/short-collections`, this.headers)
            .then((response) => {
                this.setState({
                    collections: response.data,
                    loading: false,
                    collectionPricelists: response.data.map((collection) => {
                        return {
                            id: collection.id,
                            pricelist_id: 0,
                        };
                    }),
                });
                if (response.data.length === 0) {
                    this.setState({noCollections: true});
                }
            });
    }

    componentDidMount() {
        this.getCollections();
    }

    navigateToCollection(collectionId) {
        this.props.history.push(`/${this.props.seller}/collections/${collectionId}`);
    }

    getPricelistId(collectionId) {

        const pricelistStoraged = Number(localStorage.getItem('user.pricelist'));

        if(this.state.collectionPricelists && this.state.collectionPricelists.length > 0) {
            const pricelistSelected = this.state.collectionPricelists.find((collectionPricelist) => collectionPricelist.id === collectionId).pricelist_id
            if(pricelistSelected !== 0) {
                return pricelistSelected
            } else {
                return pricelistStoraged ? pricelistStoraged : 0
            }
        }
    }

    render() {
        if (this.state.loading) {
            return <LoadingComponent />;
        }
        
        const {t} = this.props;

        const pricelistStoraged = Number(localStorage.getItem('user.pricelist'));
        
        return (
            <div className='select-collection'>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>{t(`${this.props.name} | Collections`)}</title>
                    <meta name='keywords' content='collection, brands, fashion, buy, sell' />
                    <meta http-equiv='content-language' content='en' />
                    <meta
                        name='description'
                        content="Explore our exclusive fashion collections, with limited editions of unique pieces that you won't find anywhere else."
                    />
                </Helmet>
                {this.state.collections.map((collection, index) => {
                    return (
                        <div className='collection-box' key={index}>
                            <div className='slider-wrapper'>
                                <div 
                                key={index} 
                                className='image-wrapper' 
                                onClick={() => this.props.history.push(`/${this.props.seller}/collections/${collection.id}?pricelist=${this.getPricelistId(collection.id) || ""}`)}
                                >
                                    <img src={collection.list_image} alt={collection.name} />
                                </div>
                            </div>
                            <div className='collection-name'>
                                <div className='name-container'>
                                    <p>{collection.name}</p>
                                </div>
                                {collection.pricelists && collection.pricelists.length > 0 ? (
                                    <div>
                                        <select
                                            value={this.getPricelistId(collection.id) !== 0 ? this.getPricelistId(collection.id) : pricelistStoraged}
                                            onChange={(e) => {
                                                localStorage.setItem("user.pricelist", e.target.value);
                                                this.setState({
                                                    collectionPricelists: [
                                                        {
                                                            id: collection.id,
                                                            pricelist_id: Number(e.target.value),
                                                        },
                                                        ...this.state.collectionPricelists.filter(
                                                            (item) => item.id !== collection.id
                                                        ),
                                                    ],
                                                })
                                            }}>
                                            <option value=''>Select price list</option>
                                            {collection.pricelists.map((pricelist) => (
                                                <option value={pricelist.id} key={pricelist.id}>
                                                    {pricelist.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : null}
                                <div className='button-container'>
                                    <ButtonComponent
                                        type='navLink'
                                        // disabled={
                                        //     collection.pricelists.find((item) => item.id === pricelistStoraged) ? false :
                                        //     collection.pricelists &&
                                        //     collection.pricelists.length > 0 &&
                                        //     this.state.collectionPricelists.find((item) => item.id === collection.id)
                                        //         .pricelist_id === 0
                                        // }
                                        linkTo={`/${this.props.seller}/collections/${
                                            collection.id
                                        }?pricelist=${this.getPricelistId(collection.id)}`}>
                                        {}
                                        {window.localStorage.getItem('user_type') === 'buyer' ? (
                                            <>{t(collection.button, collection.button)}</>
                                        ) : (
                                            <>{t('view collection', 'view collection')}</>
                                        )}
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                    );
                })}
                {this.state.noCollections && (
                    <div className='no-collection'>
                        {t('THIS BRAND HAS NO', 'THIS BRAND HAS NO')}
                        <br />
                        {t('COLLECTION AVAILABLE', 'COLLECTION AVAILABLE')}
                        <br />
                        {t('AT THIS TIME.', 'AT THIS TIME.')}
                        <p>{t('The new collection is coming soon!', 'The new collection is coming soon!')}</p>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(withRouter(SelectCollectionBox));
